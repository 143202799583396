<template>
  <!-- <Header type="login" /> -->

  <!--  -->
  <div class="layout">
    <div class="container py-5 mt-5">
      <div class="container-inner mt-5">
        <div class="loader-wrapper" v-if="loading">
          <div class="loader"></div>
        </div>
        <div
          v-else-if="registrationError"
          class="row"
        >
          <h1 class="font-primary text-center success-heading mt-5 pt-5">{{$t('SOMETHING_WENT_WRONG')}}</h1>
          <p class="auth-explanation-text">{{ errorMessage || $t('UNEXPECTED_ERROR_OCCURRED')}}</p>
        </div>
        <div
          v-else
          :class="
            'row d-flex justify-content-between py-5 tabContent' +
            (isActive == 't1' ? 'active' : '')
          "
        >
          <div class="col-12 d-flex justify-content-center">
            <div
              class="
                d-flex
                flex-column
                align-items-center
                register-step-wrapper
              "
            >
              <h1 class="font-primary mb-4" v-if="!activeStep == 5">
                {{$t('CREATE_YOUR_ACCOUNT')}}
              </h1>
              <div
                :class="{ 'active-step': activeStep == 1 }"
                v-if="activeStep == 1"
              >
                <h3
                  class="
                    mb-4
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                    flex-wrap
                  "
                >
                  <img
                    class="me-3 register-step-icon"
                    :src="num1Icon"
                    alt=""
                  />{{$t('SELECT_ACCOUNT_TYPE')}}
                </h3>
                <span class="mb-4 d-block text-center auth-explanation-text">
                  {{$t('ACCOUNT_TYPE_EXPLANATION')}}
                </span>
                <div class="account-types-wrapper d-flex mt-4 pt-2">
                  <div
                    @click="chooseAccountType('individual')"
                    class="
                      me-2
                      account-type
                      personal-account
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                    "
                  >
                    <img
                      class="mb-2"
                      :src="
                        selectedAccountType === 'individual'
                          ? blueHouse
                          : greyHouse
                      "
                      alt=""
                    />
                    <span
                      :class="
                        selectedAccountType === 'individual' ? 'active' : ''
                      "
                      
                      >{{$t('INDIVIDUAL')}}</span
                    >
                  </div>
                  <div
                    @click="chooseAccountType('corporate')"
                    class="
                      ms-2
                      account-type
                      corporate-account
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column
                      disabled
                    "
                  >
                    <img
                      class="mb-2"
                      :src="
                        selectedAccountType === 'corporate'
                          ? blueBuilding
                          : greyBuilding
                      "
                      alt=""
                    />
                    <span
                      :class="
                        selectedAccountType === 'corporate' ? 'active' : ''
                      "
                      >{{$t('CORPORATE')}}</span
                    >
                  </div>
                </div>
                <button
                  @click="onChooseAccountType(accountType, key)"
                  class="button w-100"
                >
                  {{$t('NEXT')}}
                </button>
                <span v-if="invalidStep1" class="registration-error mt-5"
                  >{{$t('SELECT_ACCOUNT_TYPE_ERROR')}}</span
                >
                <router-link
                  class="login-link text-center d-block mt-5"
                  to="/login"
                  >{{$t('ALREADY_HAVE_ACCOUNT')}}</router-link
                >
              </div>
              <div
                :class="{ 'active-step': activeStep == 2 }"
                v-if="activeStep == 2"
                class="register-step-wrapper"
              >
                <h3
                  class="
                    mb-4
                    text-center
                    d-flex
                    justify-content-center
                    align-items-center
                    flex-wrap
                  "
                >
                  <img class="me-2" :src="num2Icon" alt="" /> {{$t('COUNTRY_SELECTION')}}
                </h3>
                <p class="text-center auth-explanation-text">
                  {{$t('REGISTER_COUNTRY_EXPLANATION')}}
                </p>
              </div>
              <div
                :class="{ 'active-step': activeStep == 3 }"
                v-if="activeStep == 3"
                class="register-step-wrapper"
              >
                <h3
                  class="mb-4 d-flex align-items-center justify-content-center"
                >
                  <img class="me-2" :src="num3Icon" alt="" /> {{$t('LOGIN_INFORMATION')}}
                </h3>
                <span class="mb-4 mt-4 auth-explanation-text">{{$t('REGISTER_COUNTRY_EXPLANATION')}}</span>
              </div>
              <div
                :class="{ 'active-step': activeStep == 4 }"
                v-if="activeStep == 4"
              >
                <h3
                  class="mb-4 d-flex align-items-center justify-content-center"
                >
                  <img :src="num4Icon" class="me-2" alt="" /> {{$t('FINANCIAL_INFORMATION')}}
                </h3>
                <p class="text-center auth-explanation-text">
                  {{$t('BIC_EXPLANATION')}}
                </p>
                <p class="w-100 text-center validation-error" :key="error" v-for="error of errorMessages">
                  {{error.replace('eth', 'ETH').replace('btc', 'BTC')}}
                </p>
                <p v-if="emailTaken" class="resend-mail-msg text-center w-100"><span>{{$t('DID_NOT_GET_CONFIRMATION_EMAIL')}}</span> <span @click="resendEmail" class="resend-link">{{$t('RESEND_IT')}}</span></p>
              </div>
              <div
                class="d-flex flex-column align-items-center"
                :class="{ 'active-step': activeStep == 5 }"
                v-if="activeStep == 5"
              >
                <h2 CLASS="text-center">{{$t('REGISTER_SUCCESS')}}</h2>
                <img class="mt-4 mb-3" :src="checkIcon" alt="" />
                <p class="text-center auth-explanation-text">
                    {{$t('REGISTER_SUCCESS_TEXT')}}
                </p>
                <p class="text-center auth-explanation-text">
                  {{$t('REGISTER_SUCCESS_NOTE')}}
                </p>
                <div class="d-flex flex-column mt-4 align-items-center">
                  <p class="text-center auth-explanation-text">{{$t('DID_NOT_GET_EMAIL')}}</p>
                  <button class="button resend-mail-btn" @click="resendEmail">{{$t('RESEND_EMAIL')}}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 d-flex justify-content-center">
            <form
              @submit.prevent
              autocomplete="off"
              class="register-form register-step-wrapper"
              v-if="activeStep == 2 || activeStep == 3 || activeStep == 4"
            >
              <div v-if="activeStep == 2">
                <div class="form-group">
                  <label>{{$t('COUNTRY')}}*</label>
                  <select
                    id="country_id"
                    v-model="form.stepTwo.country_id"
                    class="w-100"
                  >
                    <option :selected="true" style="display: none" :value="''">
                      {{$t('SELECT_COUNTRY')}}
                    </option>
                    <template
                      v-for="(country, key) in countries"
                      v-bind:key="key"
                    >
                      <option :value="key">{{ country }}</option>
                    </template>
                  </select>
                </div>
                <p class="validation-error">{{ errors.country_id }}</p>
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <!-- <button
                    class="button lg me-5"
                    @click.prevent="onClickPrevious"
                    v-if="activeStep == 2"
                  >
                    Previous
                  </button> -->
                  <button
                    class="button w-100"
                    @click.prevent="onClickNext"
                    v-if="activeStep == 2"
                  >
                    {{$t('NEXT')}}
                  </button>
                </div>
                <span class="registration-error mt-5" v-if="invalidStep2"
                  >{{$t('SELECT_COUNTRY_BEFORE_PROCEED')}}</span
                >
                <span class="mt-5 stepper-back" @click.prevent="onClickPrevious"
                  >{{$t('BACK_TO_PREV_STEP')}}</span
                >
              </div>

              <div v-if="activeStep == 3">
                <div class="form-group">
                  <label>{{$t('EMAIL')}}*</label>
                  <input
                    v-model="form.stepThree.email"
                    class="customForm"
                    type="email"
                    name="email"
                    :placeholder="$t('ENTER_EMAIL')"
                  />
                </div>
                <p v-if="errors.email" class="validation-error">{{ errors.email }}</p>

                <div class="form-group position-relative">
                  <label>{{$t('PASSWORD')}}*</label>
                  <input
                    v-model="form.stepThree.password"
                    class="customForm"
                    type="password"
                    name="password"
                    :placeholder="$t('CREATE_PASSWORD')"
                  />
                  <p v-if="errors.password" class="validation-error">{{ errors.password }}</p>
                  <div tabindex="-1" class="info-wrapper">
                      <div class="info-icon">
                          i
                      </div>
                      <div class="info-box">
                          <p class="mb-0 pe-0">{{$t('PASSWORD_REQUIREMENTS')}}</p>
                      </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>{{$t('CONFIRM_PASSWORD')}}*</label>
                  <input
                    v-model="form.stepThree.confirmPassword"
                    class="customForm"
                    type="password"
                    name="passwordRepeat"
                    :placeholder="$t('CONFIRM_YOUR_PASSWORD')"
                  />
                </div>
                <p v-if="errors.confirmPassword" class="validation-error">{{ errors.confirmPassword }}</p>
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <!-- <button
                    class="button lg me-5"
                    @click.prevent="onClickPrevious"
                    v-if="activeStep == 3"
                  >
                    Previous
                  </button> -->
                  <button
                    class="button w-100"
                    @click.prevent="onClickNext"
                    v-if="activeStep == 3"
                  >
                    {{$t('NEXT')}}
                  </button>
                </div>
                <span class="stepper-back" @click.prevent="onClickPrevious"
                  >{{$t('BACK_TO_PREV_STEP')}}</span
                >
              </div>

              <div v-if="activeStep == 4">
                <!-- <div class="form-group">
                  <label>{{$t('IBAN')}}*</label>
                  <input
                    v-model="form.stepFour.iban"
                    class="customForm"
                    :placeholder="$t('ENTER_IBAN')"
                  />
                </div>
                <p class="validation-error" v-if="invalidIban">
                  {{$t('PLEASE_ENTER_VALID_IBAN')}}
                </p>
                <p class="validation-error" v-if="ibanEmpty">
                  {{$t('PLEASE_ENTER_IBAN')}}
                </p>
                <div class="form-group">
                  <label>{{$t('BIC')}}*</label>
                  <input
                    v-model="form.stepFour.bic_code"
                    class="customForm"
                    :placeholder="$t('ENTER_BIC')"
                  />
                </div>
                <p class="validation-error" v-if="noBic">{{$t('PLEASE_ENTER_BIC')}}</p>
                <p class="validation-error">
                  {{ errors.iban ? errors.iban : " " }}
                </p> -->
                <div class="form-group">
                  <label>{{$t('ETH_ADDRESS')}}*</label>
                  <input
                    v-model="form.stepFour.ethAddress"
                    class="customForm"
                    type="text"
                    name="receiving-address"
                    :placeholder="$t('ENTER_WALLET_ADDRESS_ETH')"
                  />
                </div>
                <p class="validation-error" v-if="invalidEthFormat">{{$t('INVALID_ETH_FORMAT')}}</p>
                <!-- <p class="validation-error" v-if="invalidEthereumAddress">{{$t('INVALID_ETH_ADDRESS')}}</p> -->
                <p class="validation-error" v-if="noEthAddress">{{$t('PLEASE_ENTER_ETH_ADDRESS')}}</p>
                <p class="validation-error">{{ errors.ethAddress }}</p>

                <!-- <div class="form-group">
                  <label>{{$t('BTC_ADDRESS')}}*</label>
                  <input
                    v-model="form.stepFour.btcAddress"
                    class="customForm"
                    type="text"
                    name="receiving-address"
                    :placeholder="$t('ENTER_WALLET_ADDRESS_BTC')"
                  />
                </div>
                <p class="validation-error" v-if="invalidBtcFormat">{{$t('INVALID_BTC_FORMAT')}}</p>
                <p class="validation-error" v-if="noBtcAddress">{{$t('PLEASE_ENTER_BTC_ADDRESS')}}</p>
                <p class="validation-error">{{ errors.btcAddress }}</p> -->
                <div
                  class="d-flex align-items-center justify-content-center my-5"
                >
                  <button
                    class="button w-100"
                    type="button"
                    @click.prevent="register"
                    v-if="activeStep == 4"
                  >
                    {{$t('SUBMIT')}}
                  </button>
                </div>
                <span @click.prevent="onClickPrevious" class="stepper-back"
                  >{{$t('BACK_TO_PREV_STEP')}}</span
                >
              </div>
              <!-- <div class="form-group mt-4 pt-2">
                <button @click="register" type="button" class="button lg">
                  Submit
                </button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  -->

  <!-- <Footer/> -->
</template>

<script>
import { defineComponent } from "vue";
// import Header from "../../components/Header.vue";
// import Footer from "../../components/Footer.vue";
import config from "../../config";
import num1Icon from "../../assets/images/num-1.svg";
import num2Icon from "../../assets/images/num-2.svg";
import num3Icon from "../../assets/images/num-3.svg";
import num4Icon from "../../assets/images/num-4.svg";
import checkIcon from "../../assets/images/check-blue.svg";
import blueHouse from "../../assets/images/house-blue.svg";
import greyHouse from "../../assets/images/house-grey.svg";
import blueBuilding from "../../assets/images/building-blue.svg";
import greyBuilding from "../../assets/images/building-grey.svg";
import { ethers } from 'ethers';

function isValidIBANNumber(input) {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""),
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
    digits;
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  return mod97(digits);
}

function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export default defineComponent({
  name: "Register",
  data: function () {
    return {
      errorMessages: [],
      emailTaken: false,
      noEthAddress: false,
      noBtcAddress: false,
      noBic: false,
      invalidEthereumAddress: false,
      invalidStep1: false,
      invalidStep2: false,
      ibanEmpty: false,
      invalidIban: false,
      isActive: "t1",
      countries: [],
      accountTypes: [],
      blueHouse: blueHouse,
      greyHouse: greyHouse,
      blueBuilding: blueBuilding,
      greyBuilding: greyBuilding,
      num1Icon: num1Icon,
      num2Icon: num2Icon,
      num3Icon: num3Icon,
      num4Icon: num4Icon,
      checkIcon: checkIcon,
      registrationError: false,
      loading: false,
      invalidEthFormat: false,
      invalidBtcFormat: false,
      form: {
        stepTwo: {
          country_id: "",
        },
        stepThree: {
          email: "",
          password: "",
          confirmPassword: "",
        },
        stepFour: {
          // iban: "",
          // bic_code: "",
          ethAddress: '',
          btcAddress: ''
        },
      },
      selectedAccountType: null,
      errors: {},
      activeStep: 1,
      selectAccountTypeLabel: null,
      selectAccountTypeValue: null,
      errorMessage: [],
    };
  },
  components: {},
  mounted: function () {
    this.getCountries();
    // this.getAccountTypes();
  },
  methods: {
    chooseAccountType(accType) {
      this.invalidStep1 = false;
      this.selectedAccountType = accType;
    },
    isRequired(value) {
      return value ? true : "This field is required";
    },
    test(tab) {
      console.log(tab);
      this.isActive = tab;
    },
    register() {
      this.errorMessages = [];
      let validation = 0;
      this.ibanEmpty = false;
      this.invalidIban = false;
      // if (!this.form.stepFour.iban) {
      //   this.ibanEmpty = true;
      //   validation++;
      // }

      // if (!this.form.stepFour.bic_code) {
      //   this.noBic = true;
      // } else {
      //   this.noBic = false;
      // }

      if (!this.form.stepFour.ethAddress) {
        this.noEthAddress = true;
        validation++;
      } else {
        try {
          ethers.utils.getAddress(this.form.stepFour.ethAddress);
          this.invalidEthFormat = false;
        } catch(err) {
          this.invalidEthFormat = true;
          validation++;
        }
        this.noEthAddress = false;
      }

      // if (this.form.stepFour.btcAddress && !this.form.stepFour.btcAddress.match(/^(bc1|[123])[a-zA-HJ-NP-Z0-9]{25,39}$/g)) {
      //   this.invalidBtcFormat = true;
      //   validation++;
      // } else {
      //   this.invalidBtcFormat = false;
      // }

      // if (!this.form.stepFour.btcAddress) {
      //   this.noBtcAddress = true;
      //   validation++;
      // } else {
      //   this.noBtcAddress = false;
      // }

      // if (!isValidIBANNumber(this.form.stepFour.iban)) {
      //   this.invalidIban = true;
      //   this.ibanEmpty = false;
      // } else {
      //   this.invalidIban = false;
      // }

      if (validation > 0) {
        return;
      }

      this.loading = true;
      this.invalidEthereumAddress = false;

      fetch(`${config.API}/api/v1/register`, {
        method: "POST",
        headers: {
          ...config.defaultHeaders,
          'Content-Type': "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          bic_code: this.form.stepFour.bic_code,
          country_id: this.form.stepTwo.country_id,
          email: this.form.stepThree.email,
          password: this.form.stepThree.password,
          password_confirmation: this.form.stepThree.confirmPassword,
          eth_address: this.form.stepFour.ethAddress,
          btc_address: this.form.stepFour.btcAddress,
          account_type: this.selectAccountTypeValue,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            data.errors.forEach(error => {
              if (error == 'Email is already taken') {
                this.emailTaken = true;
              } else {
                this.emailTaken = false;
              }
            })
          }
          if (data.errors && data.errors.length) {
            this.errorMessages = data.errors;
          } else {
            this.activeStep++;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log('error', error);
          this.$toast.error(error);
          this.registrationError = true;
          this.loading = false;
        });
    },
    handleErrors(errors) {
      var errorStr = "";

      if (errors.length) {
        errors.forEach((value) => {
          errorStr = errorStr + value + "<br>";
        });
      }
      this.$toast.error(errorStr);
    },
    resendEmail() {
      fetch(`${config.API}/api/email/verify/resend`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.form.stepThree.email
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.errors) {
          // this.errorMessages = [];
          // console.log(data.errors);
          this.$toast.error(data.errors);
        } else {
          this.$toast.success(this.$t('EMAIL_RESEND_SUCCESS'));
        }
      })
      .catch(err => {
        console.log(err);
      })
    },
    getCountries() {
      fetch(`${config.API}/api/v1/countries`, {
        method: "GET",
        headers: {
          ...config.defaultHeaders,
          'Content-Type': "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => res.json())
      .then((data) => {
        this.countries = data.data.countries;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    onChooseAccountType(label, value) {
      if (!this.selectedAccountType) {
        this.invalidStep1 = true;
        return;
      }
      this.selectAccountTypeLabel = label;
      this.selectAccountTypeValue = value;
      this.activeStep == 1;
      this.activeStep++;
    },

    onClickPrevious() {
      this.activeStep--;
      this.activeStepInd--;
    },
    onClickNext() {
      if (this.activeStep == 2) {
        if (!this.form.stepTwo.country_id) {
          this.invalidStep2 = true;
          return;
        } else {
          this.errors.country_id = null;
          this.activeStep++;
        }
      } else if (this.activeStep == 3) {
        let validationResult = 0;
        for (let item in this.form.stepThree) {
          if (!this.form.stepThree[item]) {
            this.errors[item] = this.$t('THIS_FIELD_IS_REQUIRED');
            validationResult++;
          } else {
            this.errors[item] = null;
          }
          if (item === "email") {
            if (!this.form.stepThree[item]) {
              this.errors[item] = this.$t('THIS_FIELD_IS_REQUIRED');
              validationResult++;
            } else if (!this.form.stepThree[item].match(/\S+@\S+\.\S+/g)) {
              this.errors[item] = this.$t('PLEASE_ENTER_VALID_EMAIL');
              validationResult++;
            }
          }
          if (item === "password") {
            if (!this.form.stepThree[item]) {
              this.errors[item] = this.$t('THIS_FIELD_IS_REQUIRED');
              validationResult++;
            } else if (this.form.stepThree[item].length < 8) {
              this.errors[item] = this.$t('PASSWORD_EIGHT_CHARS');
              validationResult++;
            } else if (!this.form.stepThree[item].match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gi)) {
              this.errors[item] = this.$t('PASSWORD_DOES_NOT_MEET_REQUIREMENTS');
              validationResult++;
            } else {
              this.errors[item] = '';
            }
          }
          if (item === "confirmPassword") {
            if (this.form.stepThree[item] !== this.form.stepThree.password) {
              this.errors[item] = this.$t('PASSWORD_CONFIRM_NO_MATCH');
              validationResult++;
            }
          }
        }
        if (validationResult > 0) {
          return;
        }
        this.activeStep++;
      } else if (this.activeStep == 4) {
        let validationResult = 0;
        for (let item in this.form.stepFour) {
          if (!this.form.stepFour[item]) {
            this.errors[item] = this.$t('THIS_FIELD_IS_REQUIRED');
            validationResult++;
          } else {
            this.errors[item] = null;
          }
          // if (item === "iban") {
          //   if (!this.form.stepThree[item]) {
          //     this.errors[item] = this.$t('THIS_FIELD_IS_REQUIRED');
          //     validationResult++;
          //   }
          // }
        }
        if (validationResult > 0) {
          return;
        }
        this.activeStep++;
      }
    },
  },
  watch: {
    "form.stepTwo.country_id": function () {
      this.invalidStep2 = false;
      this.errors.country_id = "";
    },
    "form.stepThree.email": function () {
      this.errors.email = "";
    },
    "form.stepThree.password": function () {
      this.errors.password = "";
    },
    "form.stepThree.confirmPassword": function () {
      this.errors.confirmPassword = "";
    },
    "form.stepFour.iban": function () {
      this.errors.iban = "";
    },
    "form.stepFour.ethAddress": function () {
      this.errors.ethAddress = "";
    },
    "form.stepFour.btcAddress": function () {
      this.errors.btcAddress = "";
    },
  },
});
</script>
